import * as React from 'react';
import './divider.fragment.scss';

export const Divider = () => {
    return (
        <>
            <div className={'divider'}/>
        </>
    );
}

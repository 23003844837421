import * as React from 'react';
import Logo from '../images/logo.png';

export const NavbarBrand = () => {
    return (
        <>
            <img src={Logo} alt={'Witold Mucha'}/>
        </>
    );
}
